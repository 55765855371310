import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import media from 'styles/media';
import { useI18n } from 'services/i18n';
import { Container, Row, Col } from 'styles/grid';
import Layout from 'components/layout';
import SEO from 'components/seo';
import Links from 'components/links.js';
import { SecondaryButton } from 'components/buttons';
import { PrimaryTitle, Text } from 'components/texts';
import * as BlogPostStyle from './blogpost.module.css';

const Section = styled.section`
  max-width: 100%;
  margin-bottom: 30px;

  ${media.lg`
    padding: 0 15%;
  `}
`;

const ResponsiveCenteredCol = styled(Col)`
  text-align: center;
  ${media.md`
    text-align:inherit;
  `}
`;

const UserVoiceSessions = () => {
  const [t, language] = useI18n();
  let blogPath = '';
  if (typeof window !== 'undefined') {
    let rawPath = window.location.pathname;
    if (rawPath[rawPath.length - 1] === '/') {
      rawPath = rawPath.slice(0, rawPath.length - 1);
    }
    blogPath = rawPath.split('/');
    blogPath = blogPath[blogPath.length - 1];
  }
  const data = useStaticQuery(graphql`
    query {
      articles: allMysqlPostTitle(
        filter: { post_type: { eq: "post" }, post_status: { eq: "publish" } }
      ) {
        edges {
          node {
            ID
            post_title
            post_name
            post_author
            post_content
            post_date
            guid
          }
        }
      }
      meta_data: allMysqlPostMeta {
        nodes {
          meta_id
          meta_key
          meta_value
          post_id
        }
      }
      userVoice: file(relativePath: { eq: "blog/uservoice.png" }) {
        childImageSharp {
          fluid(maxWidth: 820, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      userRatings: file(relativePath: { eq: "blog/userratings.png" }) {
        childImageSharp {
          fluid(maxWidth: 820, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const postIdToDescriptionImage = {};
  for (let i = 0; i < data.meta_data.nodes.length; i++) {
    if (data.meta_data.nodes[i].meta_key === 'image_description') {
      postIdToDescriptionImage[data.meta_data.nodes[i].post_id] =
        data.meta_data.nodes[i].meta_value;
    }
  }

  const articles = data.articles.edges
    .map(rule => rule.node)
    .filter(ruleName => [blogPath].findIndex(x => x === ruleName.post_name) !== -1);

  function markupHelper(article) {
    return {
      context: 'http://schema.org',
      type: 'Article',
      name: article.post_title,
      datePublished: article.post_date,
      image: postIdToDescriptionImage[article.ID],
    };
  }

  const Article = ({ article }) => (
    <Container className={BlogPostStyle.post}>
      <Row style={{ marginBottom: 30 }}>
        <ResponsiveCenteredCol sm={12} md={12}>
          <SecondaryButton to="/blog" text={t('blog.index')} $backButton />
        </ResponsiveCenteredCol>
      </Row>
      <Row>
        <Col sm={12} md={12}>
          <PrimaryTitle>{article.post_title}</PrimaryTitle>
          <Text weight="bold">{new Date(article.post_date).toLocaleDateString()}</Text>
        </Col>
      </Row>
      <Row style={{ marginLeft: 1 }}>
        <div
          dangerouslySetInnerHTML={{
            __html: article.post_content.replaceAll('http://blog.fitle', 'https://blog.fitle'),
          }}
        />
      </Row>
    </Container>
  );

  return (
    <Layout>
      {articles.map((article, i) => (
        <div key={i}>
          <SEO
            title={t('blog.user-voice-sessions.title')}
            description={t('blog.user-voice-sessions.description')}
            lang={language}
            markupHelper={markupHelper({ article })}
          />
          <Section>
            <Article article={article} />
          </Section>
        </div>
      ))}
      <Links withTitle={true} links={['plugin', 'dashboard']} />
    </Layout>
  );
};

export default UserVoiceSessions;
